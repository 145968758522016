<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Mis solicitudes de asistencia diferida
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podrá visualizar la información de cada una de las
              solicitudes realizadas.</span
            >
          </h3>
        </div>
        <div class="card-toolbar"></div>
      </div>
      <div class="card-body">
        <!-- begin:: diferred late arrival record -->
        <v-card elevation="0" class="ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2">
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="6" lg="4">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar..."
                  v-model="diferredLateArrivalRecord.search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="diferredLateArrivalRecord.headers"
                  :items="diferredLateArrivalRecord.data"
                  :search="diferredLateArrivalRecord.search"
                  :items-per-page="-1"
                  :loading="diferredLateArrivalRecord.isLoading"
                >
                  <!-- Data fields -->

                  <template v-slot:[`item.request_reason.name`]="{ item }">
                    <p
                      class="text-body-1 font-weight-bold mb-0 blue-grey--text text--darken-2"
                    >
                      {{ item.request_reason?.name }}
                    </p>
                  </template>

                  <template v-slot:[`item.user?.first_name`]="{ item }">
                    <p class="font-weight-medium text-body-1 mb-0">
                      {{ item.user?.first_name }} {{ item.user?.last_name }}
                    </p>
                  </template>

                  <template
                    v-slot:[`item.latest_tracking.request_status`]="{ item }"
                  >
                    <v-chip
                      class="ma-2 font-weight-medium"
                      small
                      :color="
                        getRequestStatusColor(
                          item.latest_tracking?.request_status?.id
                        )
                      "
                      outlined
                    >
                      <v-icon left small
                        >mdi-{{
                          getRequestStatusIcon(
                            item.latest_tracking?.request_status?.name
                          )
                        }}</v-icon
                      >
                      {{ item.latest_tracking?.request_status?.name }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.formatted_date`]="{ item }">
                    <p class="font-weight-medium text-body-1 mb-0">
                      {{ item.formatted_date }}
                    </p>
                  </template>

                  <!-- begin: Actions -->

                  <template v-slot:[`item.actions`]="{ item }">
                    <template>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="
                              selectDeferredLateArrivalRequest(item, false);
                              showMdlOfLateArrivalRequestDetail();
                            "
                            color="light-blue darken-1"
                            class=""
                            v-bind="attrs"
                            v-on="on"
                            :disabled="diferredLateArrivalRecord.isLoading"
                          >
                            mdi-open-in-new
                          </v-icon>
                        </template>
                        <span>Ver detalle</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="
                        canApproveRequest(
                          item.latest_tracking?.request_status?.id
                        )
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :disabled="diferredLateArrivalRecord.isLoading"
                            @click="
                              selectDeferredLateArrivalRequest(item, true);
                              showMdlOfLateArrivalRequestDetail();
                            "
                            class="ml-2"
                            color="orange darken-1"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-order-bool-ascending-variant
                          </v-icon>
                        </template>
                        <span>Revisar solicitud</span>
                      </v-tooltip>
                    </template>
                  </template>
                  <!-- end: Acions -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->

    <DeferredLateArrivalRequestDetailModal
      ref="mdlDeferredLateArrivalRequestDetail"
      :request="selectedDeferredLateArrivalRequest"
      :requestReasons="requestReasons.data"
      @reloadDeferredLateArrivalRequest="checkUserType"
    ></DeferredLateArrivalRequestDetailModal>
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import requestStatusRepository from "@/repositories/requestStatusRepository";
import requestReasonRepository from "@/repositories/requestReasonRepository";
import attendanceTimeLimitRepository from "@/repositories/attendanceTimeLimitRepository";
import DeferredLateArrivalRequestDetailModal from "@/components/modals/requests/deferredLateArrivalRequestDetailModal.vue";

export default {
  name: "MyRequests",
  title: "Mis solicitudes | GE ITR",
  components: {
    DeferredLateArrivalRequestDetailModal,
  },
  data() {
    return {
      requests: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Estado de la solicitud", value: "request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      limitDateExtensions: {
        headers: [
          { text: "Razón de la solicitud", value: "request_reason" },
          { text: "Estado de la solicitud", value: "request_status" },
          { text: "Fecha de solicitud", value: "created_at" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
      },
      diferredLateArrivalRecord: {
        headers: [
          { text: "Razón", value: "request_reason.name" },
          { text: "Solicitada por", value: "user.first_name" },
          {
            text: "Estado",
            value: "latest_tracking.request_status",
            align: "center",
          },
          { text: "Día a diferir", value: "formatted_date" },
          { text: "Acciones", value: "actions", sortable: false },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: [],
      },
      selectedDeferredLateArrivalRequest: {},
      requestStatusColorById: {
        1: {
          color: "amber",
          icon: "clock",
        },
        2: {
          color: "success",
          icon: "check-circle",
        },
        3: {
          color: "red",
          icon: "close-circle",
        },
      },
      requestReasons: {
        isLoading: false,
        data: [],
      },
      requestStatuses: { data: [], isLoading: false },
      REQUEST_CLASIFICATION_ID: 2,
      selectedRequest: {},
      selectedLimitDateExtension: {},
      tab: null,
      userTypesThatCanApproveRequest: [
        "Superadministrador",
        "Superadmin",
        "Coordinación de disciplina",
        "Coordinación académica",
        "Coordinación técnica",
        "Padre director",
      ],
    };
  },
  props: {
    // Se recibe el id de la solicitud
    r: {
      type: String,
    },
    // Se recibe el id de la solicitud de extensión
    lde: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Solicitudes asistencia diferida",
        route: "my_deferred_assistance_requests",
      },
    ]);
    this.getRequestStatuses();
    this.getRequestReasons();
    this.checkUserType();
  },
  methods: {
    checkUserType() {
      let userTypeFound = false;
      if (
        this.userType.user_type !== null &&
        this.userType.user_type !== undefined
      ) {
        for (let i = 0; i < this.userTypesThatCanApproveRequest.length; i++) {
          // Convertir el elemento del array a minúsculas y sin espacios en blanco adicionales
          const userTypeInArray = this.userTypesThatCanApproveRequest[i]
            .trim()
            .toLowerCase();
          if (
            userTypeInArray === this.userType.user_type.trim().toLowerCase()
          ) {
            userTypeFound = true;
            break;
          }
        }
        if (userTypeFound) {
          // Fetch all request
          this.loadDiferredLateArrivalRequest();
          this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Solicitudes");
        } else {
          // Fetch request by user id
          this.loadDiferredLateArrivalRequestByUserId(
            this.currentUserPersonalInfo.id_user
          );
          this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Mis solicitudes");
        }
      }
    },
    loadDiferredLateArrivalRequest() {
      this.diferredLateArrivalRecord.isLoading = true;
      this.diferredLateArrivalRecord.data = [];
      attendanceTimeLimitRepository
        .getAllDeferredAttendaceRequests()
        .then(({ data }) => {
          this.diferredLateArrivalRecord.data = data.data;
          this.diferredLateArrivalRecord.data.reverse();
        })
        .catch(() => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.diferredLateArrivalRecord.isLoading = false;
        });
    },

    loadDiferredLateArrivalRequestByUserId(userID) {
      this.diferredLateArrivalRecord.isLoading = true;
      this.diferredLateArrivalRecord.data = [];
      attendanceTimeLimitRepository
        .getAllDeferredAttendaceRequestsByUser(userID)
        .then(({ data }) => {
          this.diferredLateArrivalRecord.data = data.data;
        })
        .catch(() => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.diferredLateArrivalRecord.isLoading = false;
        });
    },

    canApproveRequest(requestStatusID) {
      return (
        requestStatusID === 1 &&
        this.existInArray(
          "Revisar solicitud de asistencia diferida",
          this.currentPageActions
        )
      );
    },

    getRequestReasons() {
      this.requestReasons.isLoading = true;
      requestReasonRepository
        .getRequestReasonsByRequestClasificationId(
          this.REQUEST_CLASIFICATION_ID
        )
        .then(({ data }) => {
          this.requestReasons.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestReasons.isLoading = false;
        });
    },

    selectDeferredLateArrivalRequest(obj, editMode) {
      this.selectedDeferredLateArrivalRequest = {};
      let object = obj;
      object.editMode = editMode;
      // Creating a non reactive copie of data
      this.selectedDeferredLateArrivalRequest = { ...object };
    },

    showMdlOfLateArrivalRequestDetail() {
      this.$refs.mdlDeferredLateArrivalRequestDetail.toggleModal();
    },

    getRequestStatusColor(requestStatusId) {
      const defaultColor = "grey darken-1";

      return (
        this.requestStatusColorById[requestStatusId]?.color ?? defaultColor
      );
    },

    getRequestStatusIcon(requestStatusId) {
      const defaultIcon = "account";

      return this.requestStatusColorById[requestStatusId]?.icon ?? defaultIcon;
    },

    getRequestStatuses() {
      this.requestStatuses.isLoading = true;
      requestStatusRepository
        .getAllRequestStatuses()
        .then(({ data }) => {
          this.requestStatuses.data = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestStatuses.isLoading = false;
        });
    },
    formattedRequestReasons(id) {
      let foundReason = this.requestReasons.data.find((status) => {
        return status.id === id;
      });
      return foundReason;
    },
    selectRequest(obj) {
      // Creating a non reactive copie of data
      this.selectedRequest = { ...obj };
    },
    selectLimitDateExtension(obj) {
      // Creating a non reactive copie of data
      this.selectedLimitDateExtension = { ...obj };
    },

    showMdlOfLateArrivalRequestDetail() {
      this.$refs.mdlDeferredLateArrivalRequestDetail.toggleModal();
    },
  },
  watch: {
    "userType.user_type"(newValue) {
      if (!!newValue) {
        this.checkUserType();
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentPageActions",
      "currentUserPersonalInfo",
      "userType",
    ]),
    filteredData() {
      return this.filterData(this.requests.filters, this.requests.data);
    },

    baseApiUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL;
    },
    getUserId() {
      return this.currentUserPersonalInfo.id_user;
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
