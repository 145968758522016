import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
 * GET request to fetch limit attendace time limit
 * @returns {*}
 */
export const getAttendanceTimeLimit = () => {
  return PedagogicalDiaryService.get("attendance-time-limit");
};

export const getAllDeferredAttendaceRequests = () => {
  return PedagogicalDiaryService.get("deferred-attendance")
}

export const getAllDeferredAttendaceRequestsStudents = (requestID) => {
  return PedagogicalDiaryService.get("deferred-attendance/students/request", requestID)
}

export const getAllDeferredAttendaceRequestsByUser = (userID) => {
  return PedagogicalDiaryService.get("deferred-attendance/user", userID)
}

export const registerDeferredAttendanceRequest = (payload) => {
  return PedagogicalDiaryService.post("deferred-attendance/request", payload)
}

export const approveOrDenyDeferredAttendanceRequest = (payload) => {
  return PedagogicalDiaryService.post("deferred-attendance/handle-request-approval", payload)
}


export default {
  getAttendanceTimeLimit,
  getAllDeferredAttendaceRequests,
  getAllDeferredAttendaceRequestsByUser,
  getAllDeferredAttendaceRequestsStudents,
  registerDeferredAttendanceRequest,
  approveOrDenyDeferredAttendanceRequest
};
