<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          class="font-weight-medium text-h5 primary white--text pl-2"
          >{{
            allApproved ? "Guardar cambios" : "Añadir justificación"
          }}</v-toolbar
        >
        <v-card-text>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                :icon="
                  allApproved
                    ? 'mdi-check-circle-outline'
                    : 'mdi-account-badge-outline'
                "
                :color="allApproved ? 'success' : 'red'"
                prominent
                text
                type="success"
              >
                <span
                  v-if="allApproved"
                  class="mb-2 text-body-1 font-weight-medium mb-0"
                >
                  Todos los estudiantes han sido seleccionados para aprobar la
                  creación del registro de llegada tarde.
                </span>
                <span v-else class="mb-2 text-body-1 font-weight-medium mb-0">
                  Debido a que
                  <span class="font-weight-bold"
                    >no se aprobaron todos los estudiantes de la solicitud</span
                  >, debe adjuntar una justificación detallando el motivo.
                </span></v-alert
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form v-if="allApproved">
                <form-group name="Justificación" :validator="$v.justification">
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    counter
                    filled
                    clearable
                    rounded
                    rows="4"
                    label="Justificación"
                    v-model="justification"
                  ></v-textarea>
                </form-group>
              </v-form>
              <v-form v-else>
                <form-group name="Justificación" :validator="$v.justification">
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    counter
                    filled
                    clearable
                    rounded
                    rows="4"
                    label="Justificación"
                    v-model="justification"
                  ></v-textarea>
                </form-group>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8 px-6 mt-n4">
          <v-btn
            v-if="allApproved"
            :loading="loadingStatus"
            block
            class="red lighten-4 py-5 mt-6 font-weight-bold"
            @click="acceptRequest()"
            ><span class="red--text text--darken-2"
              >Aplicar llegadas tarde</span
            ></v-btn
          >
          <v-btn
            v-if="!allApproved && !noneApproved"
            :loading="loadingStatus"
            class="red lighten-4 py-5 font-weight-bold"
            block
            :disabled="$v.$invalid"
            @click="acceptRequest(), $v.justification.$touch()"
            ><span class="red--text text--darken-2"
              >Aplicar llegadas tarde a estudaintes seleccionados
            </span></v-btn
          >

          <v-btn
            v-if="noneApproved"
            :loading="loadingStatus"
            :disabled="$v.$invalid"
            block
            class="deep-purple lighten-4 py-5 mt-6 font-weight-bold"
            @click="denyRequest(), $v.justification.$touch()"
            ><span class="deep-purple--text text--darken-2"
              >Denegar solicitud</span
            >
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "SaveDeferredLateArrivalRequestModificationsModal",
  data() {
    return {
      dialog: false,
      justification: "",
      presetJustificationText:
        "Se ha registrado la llegada tarde en el expediante de todos los estudiantes solicitados.",
    };
  },
  props: {
    approvedStudentCountAndTotal: {
      type: Object,
    },
    loadingStatus: {
      type: Boolean,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    denyRequest() {
      this.$emit("denyRequest", this.justification);
      this.$v.$reset();
    },

    acceptRequest() {
      this.$v.$reset();
      if (this.allApproved) {
        this.$emit("acceptRequest", this.justification);

        return;
      }
      this.$emit("acceptRequest", this.justification);
    },
  },
  computed: {
    allApproved() {
      let approvedStudents = this.approvedStudentCountAndTotal
        .approvedStudentCount;
      let totalStudents = this.approvedStudentCountAndTotal.totalStudents;

      if (approvedStudents == totalStudents) {
        this.justification = this.presetJustificationText;
      }
      return approvedStudents == totalStudents;
    },
    noneApproved() {
      let approvedStudents = this.approvedStudentCountAndTotal
        .approvedStudentCount;
      return approvedStudents == 0;
    },
  },
  validations: {
    justification: {
      required: required,
      maxLength: maxLength(240),
      minLength: minLength(20),
    },
  },
};
</script>
