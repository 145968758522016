<template>
  <v-dialog v-model="dialog" width="500">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="red lighten-4" size="85">
              <v-icon dark large color="red"> mdi-alert-outline </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              Lo sentimos, hubo un error
            </p>
            <p class="text-center text-body-1 font-weight-medium">
              {{ errorMessage }}.
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text outlined @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "HandleSaveErrorModal",
  data() {
    return {
      dialog: false,
    };
  },

  props: {
    errorMessage: {
      type: String,
      default: "Hubo un error al llevar a cabo la acción solicitada",
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>