<template>
  <v-dialog persistent v-model="dialog" width="550">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="amber lighten-4" size="85">
              <v-icon dark large color="amber">
                mdi-information-outline
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              ¿Desea rechazar esta solicitud?
            </p>
            <p class="text-center text-body-1 font-weight-medium">
              Si desea rechazar esta solicitud de registro de asistencia
              diferida, debe adjuntar un breve texto detallando el motivo.
            </p>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <form-group
              name="motivo"
              :validator="$v.deferredAttendanceRequest.justification"
            >
              <v-textarea
                slot-scope="{ attrs }"
                v-bind="attrs"
                counter
                filled
                clearable
                rounded
                auto-grow
                rows="2"
                label="Motivo"
                v-model.trim="deferredAttendanceRequest.justification"
                @paste="
                  handlePaste($event, 'deferredAttendanceRequest.justification')
                "
                @keyup.enter="handleKeyUpMotive()"
              ></v-textarea>
            </form-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="deferredAttendanceRequest.isLoading"
          color="font-weight-medium"
          text
          @click="dialog = false"
        >
          Regresar
        </v-btn>
        <v-btn
          class="font-weight-medium"
          color="red"
          outlined
          @click="denyRequest()"
          :loading="deferredAttendanceRequest.isLoading"
          :disabled="deferredAttendanceRequest.isLoading"
        >
          Si, rechazar
        </v-btn>
      </v-card-actions>
    </v-card>
    <HandleSaveErrorModal
      ref="MdlHandleSaveError"
      errorMessage="No se puede registrar el rechazo de la solicitud por el momento"
    ></HandleSaveErrorModal>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import dayjs from "dayjs";
import attendanceTimeLimitRepository from "@/repositories/attendanceTimeLimitRepository";
import { required } from "vuelidate/lib/validators";
import HandleSaveErrorModal from "@/components/modals/attendance/HandleSaveErrorModal.vue";

export default {
  name: "AttachJustificationToDeniedDeferredAttendanceRequest",
  data() {
    return {
      dialog: false,

      deferredAttendanceRequest: {
        isLoading: false,
        request_deferred_attendance_id: null,
        accept: false,
        user_id: null,
        justification: "",
      },
    };
  },

  components: {
    HandleSaveErrorModal,
  },

  props: {
    request: {
      type: Object,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.$v.$reset();
    },

    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.deferredAttendanceRequest.justification =
        this.deferredAttendanceRequest.justification + paste;
    },

    denyRequest() {
      this.deferredAttendanceRequest.request_deferred_attendance_id =
        this.request.id;

      this.deferredAttendanceRequest.user_id =
        this.currentUserPersonalInfo?.id_user;

      this.$v.deferredAttendanceRequest.$touch();

      if (this.$v.deferredAttendanceRequest.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurese de adjuntar una causa o sugerencia.",
        });
        return;
      }

      this.deferredAttendanceRequest.isLoading = true;

      attendanceTimeLimitRepository
        .approveOrDenyDeferredAttendanceRequest(this.deferredAttendanceRequest)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: "Se rechazó la solicitud de asistencia diferida",
          });
          this.toggleModal();
          this.$emit("successfullyDenied");
        })
        .catch((err) => {
          console.error(err);
          this.$refs.MdlHandleSaveError.toggleModal();
        })
        .finally(() => {
          this.deferredAttendanceRequest.isLoading = false;
        });
    },
  },

  watch: {
    motive(newVal) {
      if (!!this.deferredAttendanceRequest.justification) {
        this.deferredAttendanceRequest.justification = newVal.replace(
          / {3,}/g,
          " "
        );
      }
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentPageActions"]),
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },

  validations: {
    deferredAttendanceRequest: {
      justification: {
        required,
      },
    },
  },
};
</script>