<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue">
        <v-btn
          icon
          dark
          @click="(dialog = false), reloadDeferredLateArrivalRequest()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >Detalle de la solicitud de registro de asistencia
          diferida</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid class="px-0 px-md-2 px-lg-10">
        <v-row>
          <v-col>
            <v-sheet
              rounded="lg"
              color="white"
              elevation="1"
              class="mx-auto py-5 px-7 my-5"
            >
              <v-row>
                <v-col class="pt-4">
                  <div class="black--text mb-1 flex-column">
                    <p
                      class="font-weight-bold text-body-1 mb-1 black--text ml-md-1"
                    >
                      Motivo
                    </p>
                    <span class="text-h3 font-weight-medium mb-0">
                      {{ request.request_reason?.name }}
                    </span>
                  </div>
                </v-col>
              </v-row>

              <!-- second bottom half -->
              <v-row class="mt-7">
                <v-col class="pt-0" cols="12" md="6" lg="3">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Estado de solicitud
                    </p>
                    <v-chip
                      :color="getRequestStatusColor"
                      text
                      outlined
                      class="mb-3 mt-2"
                    >
                      <p
                        :class="`text-body-1 mb-0 font-weight-bold ${getRequestStatusColor}--text`"
                      >
                        <v-icon small class="mr-2"
                          >mdi-{{ getRequestStatusIcon }}</v-icon
                        >{{ request?.latest_tracking?.request_status.name }}
                      </p>
                    </v-chip>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="12" md="6" lg="3">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Día a diferir
                    </p>
                    <span class="text-h6 font-weight-medium">
                      {{ request.formatted_date }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="12" md="12" lg="3">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Solicitado por
                    </p>
                    <span class="text-h6">
                      {{ request.user?.first_name }}
                      {{ request.user?.last_name }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="12" md="12" lg="3">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Horario de clase
                    </p>
                    <span class="text-h6">
                      {{ request.schedule?.class_time?.start_time }} -
                      {{ request.schedule?.class_time?.end_time }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="12" lg="4">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Local
                    </p>
                    <span class="text-h6">
                      {{ request.schedule?.local?.name }}
                    </span>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="12" lg="6">
                  <div class="black--text mb-1 flex-column">
                    <p class="font-weight-bold text-body-1 mb-0 black--text">
                      Asignatura
                    </p>
                    <span class="text-h6">
                      {{ request.schedule?.subject }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-auto" cols="12" md="8" lg="7" xl="8">
            <v-sheet
              rounded="lg"
              color="white"
              elevation="2"
              class="mx-auto d-flex justify-center align-center flex-column py-15 px-7 my-5"
              v-if="requestStudents.isLoading"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                class="mb-3"
                :size="50"
              ></v-progress-circular>
              <p class="text-center">Cargando estudiantes...</p>
            </v-sheet>
            <v-sheet
              rounded="lg"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
              v-else
            >
              <p class="text-h5 font-weight-bold">Listado de estudiantes</p>
              <v-sheet class="d-none d-sm-flex flex-row align-center">
                <v-container>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-left align-center pa-0 ma-0 font-weight-medium"
                    >
                      Estudiante
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                    >
                      Modificación realizada
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
              <v-hover
                v-slot="{ hover }"
                v-for="(student, index) in requestStudents.data"
                :key="index"
              >
                <v-sheet
                  :elevation="hover ? 5 : 0"
                  class="d-flex flex-column flex-sm-row align-md-center mb-1"
                  :color="`grey lighten-4`"
                  rounded="xl"
                >
                  <v-container class="py-0">
                    <v-row no-gutters class="py-4">
                      <v-col
                        cols="12"
                        sm="6"
                        class="d-flex align-center font-weight-medium pr-sm-3 mt-4 mt-sm-0"
                      >
                        <div>
                          <v-avatar size="50">
                            <img
                              :src="student.student_photo"
                              :alt="student.student_full_name"
                            />
                          </v-avatar>
                        </div>
                        <div class="ml-sm-2 align-center">
                          <p class="mb-1 text-body-1">
                            {{ student.student_code }}
                            <v-chip
                              class="ml-2"
                              v-if="student.student_is_justified"
                              small
                              :color="`primary lighten-5`"
                              ><span class="font-weight-bold primary--text"
                                >Justificado
                                <v-icon x-small>mdi-check-circle</v-icon></span
                              ></v-chip
                            >
                          </p>

                          <p class="mb-0">{{ student.student_full_name }}</p>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        class="d-flex justify-center align-center pa-0 ma-0"
                      >
                        <v-chip
                          :color="`${
                            setAttendanceStatusTheme(
                              student.old_attendance_status_id
                            ).color
                          } lighten-5`"
                          small
                          class="mr-sm-3"
                        >
                          <span
                            :class="`${
                              setAttendanceStatusTheme(
                                student.old_attendance_status_id
                              ).color
                            }--text font-weight-bold`"
                            >{{
                              formattedAttendanceStatus(
                                student.old_attendance_status_id
                              ).name
                            }}</span
                          >
                        </v-chip>

                        <v-icon>mdi-arrow-right</v-icon>

                        <v-chip
                          :color="`${
                            setAttendanceStatusTheme(
                              student.new_attendance_status_id
                            ).color
                          } lighten-5`"
                          small
                          class="ml-sm-3"
                        >
                          <span
                            :class="`${
                              setAttendanceStatusTheme(
                                student.new_attendance_status_id
                              ).color
                            }--text font-weight-bold`"
                            >{{
                              formattedAttendanceStatus(
                                student.new_attendance_status_id
                              ).name
                            }}</span
                          >
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-hover>
              <v-row v-if="request.editMode" class="mt-3">
                <v-col cols="12" class="d-flex justify-center"
                  ><v-btn
                    class="ma-2 pa-6"
                    text
                    color="red"
                    @click="showMdlDenyRequestModifications()"
                    ><p class="font-weight-medium mb-0 text-body-1 red--text">
                      Denegar cambios
                    </p></v-btn
                  >
                  <v-btn
                    class="ma-2 pa-6"
                    color="success"
                    outlined
                    @click="showMdlApprovedRequestModifications()"
                    ><p
                      class="font-weight-medium mb-0 text-body-1 success--text"
                    >
                      Aprobar cambios
                    </p></v-btn
                  >
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>

          <!-- ------------------------------------------------ -->

          <v-col class="mx-auto" cols="12" md="8" lg="5" xl="4">
            <v-sheet
              rounded="lg"
              color="white"
              elevation="2"
              class="mx-auto py-5 px-7 my-5"
            >
              <p class="text-h5 font-weight-bold">Seguimiento de solicitud</p>
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, index) in reversedRequestTracking"
                  :key="index"
                  :color="requestStatusColorById[item.request_status.id].color"
                  small
                >
                  <v-row>
                    <v-col>
                      <p class="text-h6 font-weight-bold mb-0">
                        {{ item.request_status.name }}
                      </p>
                      <p
                        class="text-body-1 font-weight-bold mb-3 text--grey text--darken-1"
                      >
                        {{ fullFormattedDate(item.created_at) }}
                      </p>
                      <p
                        class="text-body-1 text--grey text--darken-2 mb-1 font-weight-medium"
                      >
                        {{ item.user.first_name }} {{ item.user.last_name }}:
                      </p>
                      <p>
                        {{ item.justification }}
                      </p>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <AttachJustificationToDeniedDeferredAttendanceRequest
      ref="MdlDenyDeferredAttendaceRequestJustification"
      :request="request"
      @successfullyDenied="toggleModal()"
    ></AttachJustificationToDeniedDeferredAttendanceRequest>
    <ConfirmRequestApproval
      ref="MdlApprovedDeferredAttendaceRequestJustification"
      :request="request"
      @successfullyApproved="toggleModal()"
    ></ConfirmRequestApproval>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SaveDeferredLateArrivalRequestModificationsModal from "@/components/modals/requests/SaveDeferredLateArrivalRequestModifications.vue";
import latenessDeferredArrivalRequestRepository from "@/repositories/latenessDeferredArrivalRequestRepository";
import attendanceStatusRepository from "@/repositories/attendanceStatusRepository";
import attendanceTimeLimitRepository from "@/repositories/attendanceTimeLimitRepository";
import studentClassRepository from "@/repositories/studentClassRepository";
import AttachJustificationToDeniedDeferredAttendanceRequest from "@/components/modals/attendance/AttachJustificationToDeniedDeferredAttendanceRequest.vue";
import ConfirmRequestApproval from "@/components/modals/attendance/ConfirmRequestApproval.vue";
export default {
  name: "DeferredLateArrivalRequestDetailModal",
  components: {
    SaveDeferredLateArrivalRequestModificationsModal,
    AttachJustificationToDeniedDeferredAttendanceRequest,
    ConfirmRequestApproval,
  },
  data() {
    return {
      dialog: false,
      isSavingRequest: false,
      PENDING_STATUS_ID: 1,
      attendanceStatuses: [],
      requestStudents: {
        isLoading: false,
        data: [],
      },
      approvedStudentCountAndTotal: {},
      requestStatusColorById: {
        1: {
          color: "amber",
          icon: "clock",
        },
        2: {
          color: "success",
          icon: "check-circle",
        },
        3: {
          color: "red",
          icon: "close-circle",
        },
      },
    };
  },

  props: {
    request: {
      type: Object,
    },
    requestReasons: {
      type: Array,
    },
  },

  watch: {
    dialog(newValue) {
      if (newValue) {
        //fetch students from request
        this.fetchRequestStudents();
        this.getAttendanceStatus();
      }
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.reloadDeferredLateArrivalRequest();
    },

    getAttendanceStatus() {
      this.isLoadingAttendanceStatus = true;
      attendanceStatusRepository
        .getAllAttendanceStatus()
        .then((data) => {
          this.attendanceStatuses = [...data.data.data];
        })
        .catch()
        .finally(() => {
          this.isLoadingAttendanceStatus = false;
        });
    },

    setAttendanceStatusTheme(attendaceStatus) {
      //evaluating attendance status ID's
      let attendanceStatusSchemas = {
        1: {
          color: "green",
        },
        2: {
          color: "red",
        },
        3: {
          color: "orange",
        },
        4: {
          color: "blue-grey",
        },
        5: {
          color: "purple",
        },
      };

      let defaultSchema = {
        color: "grey",
      };

      if (attendanceStatusSchemas.hasOwnProperty(attendaceStatus)) {
        return attendanceStatusSchemas[attendaceStatus];
      } else {
        return defaultSchema;
      }
    },

    formattedAttendanceStatus(id) {
      let foundStatus = this.attendanceStatuses.find((status) => {
        return status.id === id;
      });
      return foundStatus;
    },

    showMdlApprovedRequestModifications() {
      this.$refs.MdlApprovedDeferredAttendaceRequestJustification.toggleModal();
    },

    showMdlDenyRequestModifications() {
      this.$refs.MdlDenyDeferredAttendaceRequestJustification.toggleModal();
    },

    fetchRequestStudents() {
      this.requestStudents.isLoading = true;
      attendanceTimeLimitRepository
        .getAllDeferredAttendaceRequestsStudents(this.request.id)
        .then(({ data }) => {
          this.requestStudents.data = data.data;
        })
        .catch(() => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.requestStudents.isLoading = false;
        });
    },

    formattedRequestReasons(id) {
      let foundReason = this.requestReasons?.find((status) => {
        return status.id === id;
      });
      return foundReason;
    },

    reloadDeferredLateArrivalRequest() {
      this.$emit("reloadDeferredLateArrivalRequest");
    },

    approveRequest(justification) {
      this.isSavingRequest = true;

      let payload = {
        request_deferred_attendance_id: this.request.id,
        user_id: this.currentUserPersonalInfo.id_user,
        justification: justification,
        accept: approvedStatus,
      };

      latenessDeferredArrivalRequestRepository
        .acceptDiferredLateArrivalRequests(payload)
        .then((res) => {
          this.fireToast({
            icon: "success",
            title: "Solicitud aprobada con exito",
          });

          //if everything is ok, then apply the late arrival to the students
          // this.createLateArrivalRecords();

          this.log = {
            user_id: this.currentUserPersonalInfo.id_user,
            action: "UPDATE",
            description: `Se APROBÓ solicitud de registro de asistencia diferida de la fecha ${this.request.date_formatted}`,
            table_groupable_type: "App\\Models\\RequestDiferredAttendance",
            table_groupable_id: `${res.data.data?.id}`,
          };

          this.addLog(this.log);
        })
        .catch((error) => {
          this.sweetAlertResponse({
            status: false,
            message: `No fue posible aprobar la solicitud, por favor contacte a soporte TI.`,
          });
          console.warn(error);
        })
        .finally(() => {
          /* the loading state is handled at after creating
           the log of the added late arrival records */
          // this.isSavingRequest = false;
          this.toggleModal();
        });
    },

    createLateArrivalRecords() {
      let students = this.request.request_diferred_late_arrival_details;
      let payload = [];

      students.forEach((item) => {
        /* the API will filter the ones the where approved
         and to those only, create the late arrival record*/
        payload.push({
          student_id: item.student?.uuid,
          user_id: this.currentUserPersonalInfo.id_user,
          date: this.request.date,
          arrival_time: item.arrive_time,
          approved: item.approved,
        });
      });

      studentClassRepository
        .createLateArrivalRecordFromApprovedRequest(payload)
        .then((res) => {
          this.fireToast({
            icon: "success",
            title: "Registros de llegada tarde creados con exito.",
          });

          this.log = {
            user_id: this.currentUserPersonalInfo.id_user,
            action: "INSERT",
            description: `Se asignó llegada tarde a los estudantes que formaban parte de la solicitud de llegada tarde diferida de la fecha ${this.request.date_formatted}`,
            table_groupable_type: "StudentClass",
            table_groupable_id: `${this.request.id}`,
          };

          this.addLog(this.log);
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title:
              "se aprobó la solicitud pero no se pudo crear el registro de llegada tarde.",
          });
        })
        .finally(() => {
          this.isSavingRequest = false;
        });
    },

    denyRequest(justification) {
      this.isSavingRequest = true;

      let payload = {
        request_id: this.request.id,
        user_id: this.currentUserPersonalInfo.id_user,
        justification: justification,
      };

      latenessDeferredArrivalRequestRepository
        .denyDiferredLateArrivalRequests(payload)
        .then((res) => {
          this.fireToast({
            icon: "success",
            title: "Solicitud denegada con exito",
          });

          this.log = {
            user_id: this.currentUserPersonalInfo.id_user,
            action: "UPDATE",
            description: `Se DENEGÓ solicitud de llegada tarde diferida de la fecha ${this.request.date_formatted}. NO se aplicó ninguna llegada tarde a los estudiantes.`,
            table_groupable_type: "App\\Models\\RequestDiferredLateArrival",
            table_groupable_id: `${this.request.id}`,
          };

          this.addLog(this.log);
        })
        .catch((error) => {
          this.sweetAlertResponse({
            status: false,
            message: `No fue posible denegar la solicitud; por favor contacte a soporte TI.`,
          });
          console.warn(error);
        })
        .finally(() => {
          this.isSavingRequest = false;
          this.toggleModal();
        });
    },

    requestItemStatus(isAproved) {
      const requestStatusId = this.request.latest_tracking?.request_status_id;

      if (requestStatusId == this.PENDING_STATUS_ID) {
        return {
          color: "deep-orange",
          icon: "clock",
          tooltip: "Pendiente de revisión",
        };
      }

      if (isAproved) {
        return {
          color: "success",
          icon: "check-circle",
          tooltip: "Llegada tarde aplicada",
        };
      }

      return {
        color: "red",
        icon: "close-circle",
        tooltip: "Llegada tarde no aplicada",
      };
    },
  },
  computed: {
    //null || undefined validators

    baseURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DEV_API_URL;
      } else {
        baseURL = process.env.LOCAL_API_URL;
      }
      return baseURL;
    },

    reversedRequestTracking() {
      return this.request.tracking?.reverse();
    },

    getRequestStatusColor() {
      const defaultColor = "grey darken-1";
      const requestStatusId = this.request.latest_tracking?.request_status.id;
      return (
        this.requestStatusColorById[requestStatusId]?.color ?? defaultColor
      );
    },

    getRequestStatusIcon() {
      const defaultIcon = "account";
      const requestStatusId = this.request?.latest_tracking?.request_status_id;
      return this.requestStatusColorById[requestStatusId]?.icon ?? defaultIcon;
    },

    requestTrakingsReordered() {
      const trackings = this.request.request_diferred_late_arrival_trackings;
      if (!!trackings) {
        return trackings.slice().reverse();
      }
      return trackings;
    },

    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
