var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-card',{staticClass:"ma-1 min-h-65vh px-4 px-md-5 px-lg-7 py-2",attrs:{"elevation":"0"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"rounded":"","filled":"","hide-details":"","prepend-icon":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.diferredLateArrivalRecord.search),callback:function ($$v) {_vm.$set(_vm.diferredLateArrivalRecord, "search", $$v)},expression:"diferredLateArrivalRecord.search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.diferredLateArrivalRecord.headers,"items":_vm.diferredLateArrivalRecord.data,"search":_vm.diferredLateArrivalRecord.search,"items-per-page":-1,"loading":_vm.diferredLateArrivalRecord.isLoading},scopedSlots:_vm._u([{key:`item.request_reason.name`,fn:function({ item }){return [_c('p',{staticClass:"text-body-1 font-weight-bold mb-0 blue-grey--text text--darken-2"},[_vm._v(" "+_vm._s(item.request_reason?.name)+" ")])]}},{key:`item.user?.first_name`,fn:function({ item }){return [_c('p',{staticClass:"font-weight-medium text-body-1 mb-0"},[_vm._v(" "+_vm._s(item.user?.first_name)+" "+_vm._s(item.user?.last_name)+" ")])]}},{key:`item.latest_tracking.request_status`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2 font-weight-medium",attrs:{"small":"","color":_vm.getRequestStatusColor(
                        item.latest_tracking?.request_status?.id
                      ),"outlined":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-"+_vm._s(_vm.getRequestStatusIcon( item.latest_tracking?.request_status?.name )))]),_vm._v(" "+_vm._s(item.latest_tracking?.request_status?.name)+" ")],1)]}},{key:`item.formatted_date`,fn:function({ item }){return [_c('p',{staticClass:"font-weight-medium text-body-1 mb-0"},[_vm._v(" "+_vm._s(item.formatted_date)+" ")])]}},{key:`item.actions`,fn:function({ item }){return [[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"light-blue darken-1","disabled":_vm.diferredLateArrivalRecord.isLoading},on:{"click":function($event){_vm.selectDeferredLateArrivalRequest(item, false);
                            _vm.showMdlOfLateArrivalRequestDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])],(
                      _vm.canApproveRequest(
                        item.latest_tracking?.request_status?.id
                      )
                    )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"disabled":_vm.diferredLateArrivalRecord.isLoading,"color":"orange darken-1"},on:{"click":function($event){_vm.selectDeferredLateArrivalRequest(item, true);
                            _vm.showMdlOfLateArrivalRequestDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-order-bool-ascending-variant ")])]}}],null,true)},[_c('span',[_vm._v("Revisar solicitud")])])]:_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)]),_c('DeferredLateArrivalRequestDetailModal',{ref:"mdlDeferredLateArrivalRequestDetail",attrs:{"request":_vm.selectedDeferredLateArrivalRequest,"requestReasons":_vm.requestReasons.data},on:{"reloadDeferredLateArrivalRequest":_vm.checkUserType}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Mis solicitudes de asistencia diferida "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Aqui se podrá visualizar la información de cada una de las solicitudes realizadas.")])])]),_c('div',{staticClass:"card-toolbar"})])
}]

export { render, staticRenderFns }