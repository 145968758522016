import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllRequestStatuses = () => {
  return ApiService.get("request-statuses");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getRequestStatus = (id) => {
  return ApiService.get("request-statuses", id);
};

/**
 * POST request to create a new stage
 * @param payload
 * @returns {*}
 */
export const createRequestStatus = (payload) => {
  return ApiService.post("request-statuses", payload);
};

/**
 * PUT request to update a specific stage
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateRequestStatus = (id, payload) => {
  return ApiService.update("request-statuses", id, payload);
};

/**
 * DELETE request to delete the specified stage
 * @param id
 * @returns {*}
 */
export const deleteRequestStatus = (id) => {
  return ApiService.delete(`request-statuses/${id}`);
};

/**
 * GET request to change status of the specified stage
 * @param id
 * @returns {*}
 */
export const changeStatusRequestStatus = (id) => {
  return ApiService.get("request-statuses/status", id);
};

export default {
  getAllRequestStatuses,
  getRequestStatus,
  createRequestStatus,
  updateRequestStatus,
  deleteRequestStatus,
  changeStatusRequestStatus,
};
