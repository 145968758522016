import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllDiferredLateArrivalRequests = () => {
  return ApiService.get("diferred-late-arrival-request");
};

/**
 * GET request to fetch one request by it's ID
 * @returns {*}
 */
export const getDiferredLateArrivalRequest = (requestId) => {
  return ApiService.get("diferred-late-arrival-request", requestId);
};

/**
 * GET request to fetch one request by it's user ID
 * @returns {*}
 */
export const getAllDiferredLateArrivalRequestsByUserId = (userId) => {
  return ApiService.get(`diferred-late-arrival-request/user/${userId}`);
};

/**
 * GET request to create request
 * @returns {
    "status": true,
    "message": "Solicitud enviada con éxito"
}
 */
export const createDiferredLateArrivalRequests = (payload) => {
  return ApiService.post("diferred-late-arrival-request", payload);
};

/**
 * GET request to accept request
 * @returns {
    "status": true,
    "message": "Solicitud aceptada con éxito"
}
 */
export const acceptDiferredLateArrivalRequests = (payload) => {
  return ApiService.post(`diferred-late-arrival-request/accept`, payload);
};

/**
 * GET request to deny request
 * @returns {
    "status": true,
    "message": "Solicitud rechazada con éxito"
}
 */
export const denyDiferredLateArrivalRequests = (payload) => {
  return ApiService.post(`diferred-late-arrival-request/deny`, payload);
};

/**
 * GET request to deny request
 * @returns {
    "status": true,
    "message": "Solicitud rechazada con éxito"
}
 */
export const getDiferredLateArrivalRequestsWithOldestTracking = () => {
  return ApiService.get("diferred-late-arrival-request/tracking/oldest");
};

/**
 * GET request to deny request
 * @returns {
    "status": true,
    "message": "Solicitud rechazada con éxito"
}
 */
export const getDiferredLateArrivalRequestsWithOldestTrackingByUser = (
  userId
) => {
  return ApiService.get(
    `diferred-late-arrival-request/tracking/latest/status/accepted-rejected/user/${userId}`
  );
};

/**
 * GET request to deny request
 * @returns {
    "status": true,
    "message": "Solicitud rechazada con éxito"
}
 */
export const getDiferredLateArrivalTrackingByRequestId = (requestId) => {
  return ApiService.get(
    `request-diferred-late-arrivals/tracking/request/${requestId}`
  );
};

export default {
  getAllDiferredLateArrivalRequests,
  getDiferredLateArrivalRequest,
  getAllDiferredLateArrivalRequestsByUserId,
  createDiferredLateArrivalRequests,
  acceptDiferredLateArrivalRequests,
  denyDiferredLateArrivalRequests,
  getDiferredLateArrivalRequestsWithOldestTracking,
  getDiferredLateArrivalRequestsWithOldestTrackingByUser,
  getDiferredLateArrivalTrackingByRequestId,
};
