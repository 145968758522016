<template>
  <v-dialog v-model="dialog" width="550">
    <v-card class="elevation-0 pt-8 pb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-avatar color="primary lighten-4" size="85">
              <v-icon dark large color="primary"> mdi-check-all </v-icon>
            </v-avatar>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <p class="text-center text-h5 font-weight-bold">
              ¿Aprobar solicitud?
            </p>
            <p class="text-center text-body-1 font-weight-medium">
              Los cambios revisados previamente serán aplicados una vez se
              apruebe la solicitud.
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="font-weight-medium" text @click="dialog = false">
          Cerrar
        </v-btn>
        <v-btn
          color="green"
          class="font-weight-medium"
          outlined
          @click="approveRequest()"
        >
          sí, aprobar
        </v-btn>
      </v-card-actions>
    </v-card>
    <HandleSaveErrorModal
      ref="MdlHandleSaveError"
      errorMessage="No se puede aprobar la solicitud por el momento"
    ></HandleSaveErrorModal>
  </v-dialog>
</template>

<script>
import HandleSaveErrorModal from "@/components/modals/attendance/HandleSaveErrorModal.vue";
import attendanceTimeLimitRepository from "@/repositories/attendanceTimeLimitRepository";
import { mapGetters } from "vuex";
export default {
  name: "ConfirmRequestApproval",
  data() {
    return {
      dialog: false,

      deferredAttendanceRequest: {
        isLoading: false,
        request_deferred_attendance_id: null,
        accept: true,
        user_id: null,
        justification:
          "Se aprobó la solicitud de registro de asistencia diferida; Los cambios solicitados fueron aplicados.",
      },
    };
  },

  components: {
    HandleSaveErrorModal,
  },

  props: {
    request: {
      type: Object,
    },
  },

  methods: {
    approveRequest() {
      this.deferredAttendanceRequest.request_deferred_attendance_id =
        this.request.id;

      this.deferredAttendanceRequest.user_id =
        this.currentUserPersonalInfo.id_user;

      this.deferredAttendanceRequest.isLoading = true;

      attendanceTimeLimitRepository
        .approveOrDenyDeferredAttendanceRequest(this.deferredAttendanceRequest)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: "Se aprobó la solicitud de asistencia diferida",
          });
          this.toggleModal();
          this.$emit("successfullyApproved");
        })
        .catch((err) => {
          console.error(err);
          this.$refs.MdlHandleSaveError.toggleModal();
        })
        .finally(() => {
          this.deferredAttendanceRequest.isLoading = false;
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentPageActions"]),
  },
};
</script>